<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Skupiny - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-6">
          <a-dropdown :placement="'bottomLeft'">
            <a-button>Hromadné akce
              <a-icon type="down"/>
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete deaktivovat tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  Deaktivovat
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowSelection="rowSelection"
        :rowKey="record => record.grp_id"
        :columns="columns"
        :loading="loading"
        :dataSource="group"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <router-link
          slot="grp_id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/group/detail/'+value"
        >#<span v-if="searchText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <template slot="customRender" slot-scope="text">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <span
          slot="active"
          slot-scope="value"
          :class="[value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger']"
        >
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in (value ? 'ano' : 'ne').toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value ? 'ano' : 'ne'}}</template>
        </span>
        <span slot="action" slot-scope="record">
          <router-link :to="'/group/detail/'+record.grp_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <a-popconfirm @confirm="confirmDelete([record.grp_id])" okText="Potvrdit" cancelText="Zrušit" v-if="record.grp_is_active">
            <template slot="title">
              <p>Opravdu si přejete deaktivovat položku: #{{ record.grp_id }} ?</p>
            </template>
            <a-button icon="stop" size="small">Deaktivovat</a-button>
          </a-popconfirm>
          <a-popconfirm @confirm="confirmActivate([record.grp_id])" okText="Potvrdit" cancelText="Zrušit" v-else>
            <template slot="title">
              <p>Opravdu si přejete aktivovat položku: #{{ record.grp_id }} ?</p>
            </template>
            <a-button icon="check" size="small">Aktivovat</a-button>
          </a-popconfirm>
        </span>
        <template slot="footer">
          {{ group.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools },
  data: function () {
    return {
      searchText: '',
      loading: false,
      selectedRowKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/group/add',
          title: 'Přidat',
        },
      ],
      columns: [
        {
          title: 'ID',
          dataIndex: 'grp_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.grp_id - b.grp_id,
          scopedSlots: {
            customRender: 'grp_id',
          },
        },
        {
          title: 'Název',
          dataIndex: 'grp_name',
          sorter: (a, b) => {
            return a.grp_name.localeCompare(b.grp_name)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Výchozí skupina',
          dataIndex: 'grp_is_default',
          sorter: (a, b) => a.grp_is_default - b.grp_is_default,
          scopedSlots: {
            customRender: 'active',
          },
        },
        {
          title: 'Aktivní',
          dataIndex: 'grp_is_active',
          sorter: (a, b) => a.grp_is_active - b.grp_is_active,
          scopedSlots: {
            customRender: 'active',
          },
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    group: function () {
      return this.$store.getters['group/filteredItems'](this.searchText)
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            if (this.selectedRowKeys.length === 0) {
              this.group.forEach(item => {
                if (item.children !== undefined) {
                  this.selectedRowKeys = this.selectedRowKeys.concat(item.grp_id).concat(item.children.map(x => x.grp_id))
                } else {
                  this.selectedRowKeys.push(item.grp_id)
                }
              })
            } else {
              this.selectedRowKeys = []
            }
          },
        }],
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('group/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmDelete(group) {
      this.loading = true
      this.$store.dispatch('group/deactivate', group)
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = group.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !group.includes(x)))
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
    confirmActivate(id) {
      this.loading = true
      this.$store.dispatch('group/activate', id)
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
  },
  created() {
    if (this.group.length === 0) {
      this.handleRefresh()
    }
  },
}
</script>

<style lang="scss" module scoped>

</style>
